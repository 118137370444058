var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('v-flex',[(_vm.userData)?_c('Header',{attrs:{"title":_vm.$t("global.phonebook"),"rightButtonPrimary":_vm.$t("phonebook.add_external_personn"),"rightButtonIconPrimary":"mdi-plus"},on:{"actionButtonRightPrimary":function($event){_vm.dialogFormSignUp = true}}}):_vm._e(),_c('div',{staticClass:"container-page-search mt-5"},[_c('div',{staticClass:"input-search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"search",attrs:{"placeholder":this.$i18n.t('global.search_by'),"type":"text"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}}),_c('div',{staticClass:"icon"},[_c('v-icon',{attrs:{"color":"#212121","size":"18px"}},[_vm._v("mdi-magnify")])],1)]),(_vm.userData)?_c('MycButton',{staticClass:"ml-5",attrs:{"primary":"","textColor":"white"},on:{"click":function($event){return _vm.loadData(!_vm.myNetwork)}}},[_c('span',[_vm._v(_vm._s(_vm.myNetwork ? _vm.$t("global.display_all") : _vm.$t("global.my_network")))])]):_vm._e()],1),_c('myc-card',{staticClass:"mt-8",attrs:{"isflat":""}},[_c('v-list',{attrs:{"three-line":""}},[_vm._l((_vm.usersList),function(item,index){return [_c('v-list-item',{key:item.id},[_c('v-list-item-avatar',[(item.picture_url)?_c('img',{attrs:{"src":item.picture_url}}):_c('img',{attrs:{"src":"https://firebasestorage.googleapis.com/v0/b/myc-telemedicine.appspot.com/o/pictures%2FlogoId.jpeg?alt=media&token=b27e674e-73e9-4af3-b24a-7df75ce30689"}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name)),(item.verified)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"#3772FF","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-decagram ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("global.doctor_verified")))])]):_vm._e(),(item.relation_common)?_c('span',{staticClass:"mr-2",staticStyle:{"font-family":"Inter !important","font-style":"normal !important","font-weight":"normal !important","font-size":"12px !important","line-height":"20px !important","color":"#757575 !important"}},[_vm._v(" ("+_vm._s(item.relation_common)+" "+_vm._s(_vm.$t("global.common_relations"))+")")]):_vm._e()],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.getSpecialityList()[item.speciality])}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.getCountry(item))}}),_c('p',{staticClass:"doctor-name"}),_c('p',{staticClass:"doctor-city-speacilité"})],1),(_vm.userData)?_c('v-list-item-action',[(_vm.userData.network.current.includes(item.id))?_c('div',{staticClass:"chip-teal"},[_vm._v(" "+_vm._s(_vm.$t("global.related"))+" ")]):_vm._e(),(
                  (_vm.userData.network.asking.includes(item.id) ||
                    !_vm.userData.network.current.includes(item.id)) &&
                  !_vm.userData.network.waiting.includes(item.id)
                )?_c('MycButton',{attrs:{"primary":"","small":"","textColor":"white"},on:{"click":function($event){return _vm.addToMyNetwork(item.id)}}},[(!_vm.userData.network.current.includes(item.id))?[(_vm.userData.network.asking.includes(item.id))?_c('span',[_vm._v(" "+_vm._s(_vm.$t("global.accept_demand")))]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("global.connect")))])]:_vm._e()],2):_vm._e(),(_vm.userData.network.waiting.includes(item.id))?_c('div',{staticClass:"chip-amber"},[_vm._v(" "+_vm._s(_vm.$t("global.waiting"))+" ")]):_vm._e()],1):_vm._e()],1),_c('v-divider',{key:index,attrs:{"inset":item.inset}})]})],2)],1),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1),(_vm.dialogFormSignUp)?_c('MycDialog',{attrs:{"title":_vm.$t("phonebook.send_invitation"),"width":536},on:{"close-dialog":function($event){return _vm.closeDialog()}}},[_c('div',{staticClass:"mt-8",attrs:{"text-align":"center","align":"center"}},[_c('span',{staticClass:"black--text",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.$t("global.please_provide_email"))+" ")]),_c('v-form',{staticClass:"pt-3"},[_c('v-text-field',{staticClass:"pb-0 mr-1 mt-4",attrs:{"dense":"","color":"primary","label":_vm.$t('login.user'),"name":"user","type":"text","prepend-icon":"mdi-account","outlined":""},model:{value:(_vm.emailInvitation),callback:function ($$v) {_vm.emailInvitation=$$v},expression:"emailInvitation"}})],1)],1),_c('div',{attrs:{"text-align":"center","align":"center"}},[_c('v-btn',{staticClass:"white--text mb-4",attrs:{"color":"primary","loading":_vm.loading,"block":""},on:{"click":function($event){return _vm.sendInvitation()}}},[_vm._v(" "+_vm._s(_vm.$t("global.send"))+" ")])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }