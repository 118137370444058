












































































































































































import Vue from "vue";
import * as _ from "lodash";
import DBHelper from "@/tscript/dbHelper";
import db from "@/firebase/db";
import fb from "firebase/app";
import { mapState, mapActions } from "vuex";
import Header from "@/components/global/Header.vue";
import { checkI18 } from "@/tscript/utils";
import MycDialog from "@/components/global/dialog/MycDialog.vue";
import MycCard from "../global/cards/MycCard.vue";
import MycButton from "@/components/global/buttons/MycButton.vue";
import { sendMailInvitation } from "@/components/helper/mail";
import { getSpecialityList } from "@/components/helper/general";

export default Vue.extend({
  name: "HomeContactBook",
  components: {
    Header,
    MycDialog,
    MycCard,
    MycButton,
  },
  props: ["isMobile"],
  data() {
    return {
      openSendInvitationDialog: false,
      usersList: new Array(),
      dbHelper: new DBHelper(db),
      search: "",
      loading: false,
      patientId: "",
      dialogFormSignUp: false,
      emailInvitation: "",
      myNetwork: false,
      path: "" as any,
    };
  },

  methods: {
    sendMailInvitation,
    ...mapActions("analytics", ["logAction"]),
    ...mapActions("snackbar", ["setSnackbarAction"]),
    ...mapActions("user", ["loadUserDataAction"]),
    checkI18,
    getSpecialityList,
    async addToMyNetwork(userId: string) {
      if (
        this.userData.network.asking.includes(userId) &&
        !this.userData.network.current.includes(userId)
      ) {
        const FieldValue = fb.firestore.FieldValue;
        await this.dbHelper.updateDataToCollection(
          "network",
          this.userData.network.id,
          {
            asking: FieldValue.arrayRemove(userId),
            current: FieldValue.arrayUnion(userId),
          }
        );
        const where: any = { user_id: userId };
        const network: any = await this.dbHelper.getDocFromCollectionWithWhere(
          "network",
          where
        );
        await this.dbHelper.updateDataToCollection("network", network.id, {
          waiting: FieldValue.arrayRemove(this.userData.id),
          current: FieldValue.arrayUnion(this.userData.id),
        });
      } else {
        const FieldValue = fb.firestore.FieldValue;
        await this.dbHelper.updateDataToCollection(
          "network",
          this.userData.network.id,
          {
            waiting: FieldValue.arrayUnion(userId),
          }
        );
        const where: any = { user_id: userId };
        const network: any = await this.dbHelper.getDocFromCollectionWithWhere(
          "network",
          where
        );
        await this.dbHelper.updateDataToCollection("network", network.id, {
          asking: FieldValue.arrayUnion(this.userData.id),
        });
      }
      this.loadUserDataAction(this.userData.id);
    },
    getCountry(item: any) {
      if (item?.country) {
        const countryPatient = this.countriesList.find(
          (country: any) => country.value == item.country
        );
        const city: any = item.city ? ", " + item.city : "";
        return countryPatient?.flag + countryPatient?.label + city;
      }
      return "";
    },
    loadData(myNetwork = false) {
      this.myNetwork = myNetwork;
      this.usersList = this.$store.state.usersList
        ? [...this.$store.state.usersList]
        : new Array();

      if (myNetwork) {
        this.usersList = _.filter(this.usersList, (item) =>
          this.userData.network.current.includes(item.id)
        );
      }

      this.usersList = _.sortBy(this.usersList, (contact) => contact.verified);
      this.usersList = this.usersList.map((user) => {
        user.name = user.last_name + " " + user.first_name;
        return user;
      });
    },

    closeDialog() {
      this.dialogFormSignUp = false;
      this.emailInvitation = "";
    },
    sendInvitation() {},
  },
  computed: {
    ...mapState("user", ["userData"]),
    ...mapState("countries", ["countriesList"]),
  },
  created() {
    this.logAction({ event_name: "home-contactbook" });
    this.loadData();
    this.$store.subscribe((mutation, state) => {
      if (
        (mutation.type === "setUsersList" && state?.usersList) ||
        (mutation.type === "setUserData" && state?.userData)
      ) {
        this.loadData();
      }
    });
  },

  watch: {
    search(val) {
      const specialities = this.getSpecialityList();
      let localdata = this.$store.state.usersList ?? new Array();

      localdata.map((patient: any) => {
        patient.searchname =
          patient?.first_name.toLowerCase() +
          " " +
          patient?.last_name?.toLowerCase() +
          " " +
          this.getCountry(patient);
        if (specialities[patient.speciality]) {
          patient.searchname += " " + specialities[patient.speciality];
        }
      });
      let localbeforefilter = [...localdata].filter((patient) =>
        patient.searchname.includes(val.toLowerCase())
      );
      this.usersList = _.sortBy(
        localbeforefilter,
        (patient) => patient.last_update
      );
    },
  },
});
