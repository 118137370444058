// const mailjet = require("node-mailjet").connect(
//   "0d72bda815a38c5410802f6833862ac6",
//   "0984d1b053b4c34d96ac49aef5fa98a2"
// );

const getTemplate = (type: string, locale: string) => {
  let templateId = 0;

  if (type === "invitation") {
    if (locale === "en") {
      templateId = 3360120;
    }
    if (locale === "fr") {
      templateId = 3360114;
    }
  }
  return templateId;
};

export const sendMailInvitation = async (
  to: string,
  first_name: string,
  last_name: string,
  link: string,
  locale: string
) => {
  const templateID = getTemplate("invitation", locale);
  const variables = {
    first_name: first_name,
    link: link,
    email: to,
  };

  const requestData: any = {
    Messages: [
      {
        From: {
          Email: "contact@agora.org",
          Name: "Contact Agora",
        },
        To: [
          {
            Email: to,
            Name: first_name + "  " + last_name,
          },
        ],
        TemplateID: templateID,
        TemplateLanguage: true,
        Variables: variables,
      },
    ],
  };

  // const request = mailjet
  //   .post("send", { version: "v3.1" })
  //   .request(requestData);

  // const result = await request
  //   .then((msg: any) => {
  //     console.log("sendMailScheduleHandler - success");
  //     return { success: "succes:" + to };
  //   })
  //   .catch((err: any) => {
  //     console.log("sendMailScheduleHandler - error", JSON.stringify(err));
  //     return { error: err };
  //   });
  // return result;
};
